import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { Semantic } from 'packages/components'
import { Modal } from 'packages/components/modal'
import { FormInput, FormToggle } from 'packages/components/inputs'
import { Button } from 'packages/components/buttons'
// import { ProFeatureBudge } from "packages/components/newFeatureBudge/nodes";
import { formProptypes } from 'packages/utils/formikPropTypes'
import Message from 'packages/components/message/Message'
import {
  Header,
  Content,
  StyledInput,
  Title,
  Description,
  MobileSubmitContainer,
} from '@configurator/components/modals/nodes'
import {
  DescriptionLabel,
  DescriptionWrapper,
  StyledTextareaContainer,
  ToggleContainer,
} from './nodes.js'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useSubscription } from '@configurator/providers/subscription'
import TextEditorContainer from '@configurator/containers/TextEditorContainer'
import { WEBSITE_BLOCK } from 'packages/enum'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const AlbumCreate = (
  {
    open,
    onClose,
    handleSubmit,
    errors,
    status,
    isSubmitting,
    values,
    setFieldValue,
    initialValues,
    pageId,
    mainNavigation,
    footerNavigation,
    redirectToAlbum,
  },
  { intl: { formatMessage } }
) => {
  const prevValues = usePrevious(values)
  const prevIsSubmitting = usePrevious(isSubmitting)
  const { permissions } = useSubscription()

  useEffect(() => {
    if (prevValues?.visible !== values.visible) {
      setFieldValue('hide', !values.visible)
    }
    if (prevValues?.hide !== values.hide) {
      setFieldValue('visible', !values.hide)
    }
  }, [prevValues, setFieldValue, values])

  const handleChangeDescription = React.useCallback(
    (text) => {
      setFieldValue('description', text)
    },
    [setFieldValue]
  )

  const error = Object.keys(errors).length || (status && !!status.error)
  // console.log(error, status?.error, status)
  useEffect(() => {
    if (prevIsSubmitting && !isSubmitting && !error) {
      // console.log(['here'], prevIsSubmitting, error, isSubmitting)
      const pageIndexMain = mainNavigation?.findIndex((el) => el.id === pageId)
      const pageIndex =
        pageIndexMain === -1 ? footerNavigation?.findIndex((el) => el.id === pageId) : pageIndexMain
      const navigation = pageIndexMain === -1 ? footerNavigation : mainNavigation
      if (pageIndex > -1) {
        const albumListIndex = navigation[pageIndex].blocks?.findIndex(
          (el) => el.type === WEBSITE_BLOCK.ALBUM_LIST
        )
        const newAlbum = navigation[pageIndex].blocks[albumListIndex]?.albums?.[0]
        if (newAlbum && albumListIndex > -1 && newAlbum.albumUrl) {
          const parentPageUrl = newAlbum.link.split('/')[1]
          return redirectToAlbum(parentPageUrl + `/${newAlbum.albumUrl}`)
        }
      }
    }
  }, [
    error,
    footerNavigation,
    isSubmitting,
    mainNavigation,
    pageId,
    prevIsSubmitting,
    redirectToAlbum,
  ])

  const modalStyle = {
    width: '600px',
    height: 'initial',
  }

  return (
    <Modal open={open} onClose={onClose} styles={modalStyle} mobileWide>
      <Content>
        <Semantic.Form noValidate error={error} loading={isSubmitting} onSubmit={handleSubmit}>
          <Header>
            <span>
              {formatMessage({
                id: 'newDesign.album.create.header',
              })}
            </span>
            <Button
              type='submit'
              content={formatMessage({
                id: 'newDesign.album.create.submit',
              })}
              disabled={isSubmitting}
            />
          </Header>
          <Message error={error} messages={errors} content={status && status.error} />
          <StyledInput>
            <FormInput name='title' type='text' label='newDesign.album.create.field.name' />
          </StyledInput>
          <StyledTextareaContainer>
            <DescriptionWrapper>
              <DescriptionLabel>
                {formatMessage({
                  id: 'newDesign.album.update.field.desc',
                })}
              </DescriptionLabel>
              <TextEditorContainer
                text={initialValues.description}
                handleChangeText={handleChangeDescription}
                inline
              />
            </DescriptionWrapper>
          </StyledTextareaContainer>
          <ToggleContainer>
            <div>
              <Title>
                {formatMessage({
                  id: 'newDesign.album.create.toggle.visibility.title',
                })}
              </Title>
              <Description>
                {formatMessage({
                  id: 'newDesign.album.create.toggle.visibility.desc',
                })}
              </Description>
            </div>
            <FormToggle name='hide' />
          </ToggleContainer>
          <ToggleContainer>
            <div>
              <Title>
                {formatMessage({
                  id: 'newDesign.album.create.toggle.hideHeader.title',
                })}
                {/* <ProFeatureBudge src={newFeatureIcon} alt="" /> */}
              </Title>
              <Description>
                {formatMessage({ id: 'newDesign.album.update.toggle.hideHeader.desc' })}
              </Description>
            </div>
            <div>
              <PermissionsOverlay isAllowed={permissions.HIDE_ALBUM_TITLE}>
                <FormToggle name='hideHeader' />
              </PermissionsOverlay>
            </div>
          </ToggleContainer>
          <MobileSubmitContainer>
            <Button
              type='submit'
              fluid
              content={formatMessage({
                id: 'newDesign.album.create.submit',
              })}
              disabled={isSubmitting}
            />
          </MobileSubmitContainer>
        </Semantic.Form>
      </Content>
    </Modal>
  )
}

AlbumCreate.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  pageId: PropTypes.number,
  mainNavigation: PropTypes.array,
  footerNavigation: PropTypes.array,
  redirectToAlbum: PropTypes.func,
  ...formProptypes,
}

AlbumCreate.defaultProps = {
  open: false,
  pageId: null,
  mainNavigation: [],
  footerNavigation: [],
  redirectToAlbum: () => null,
}

AlbumCreate.contextTypes = {
  intl: PropTypes.object.isRequired,
}
export default AlbumCreate
