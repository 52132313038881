import { FormDropdown } from 'packages/components/inputs'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { Dropdown as SemanticDropdown } from 'semantic-ui-react'
import { Container } from './nodes'

function Component({ intl: { formatMessage }, options, label, ...rest }) {
  return (
    <Container>
      <FormDropdown {...rest} label={formatMessage({ id: label })} options={options} fluid />
    </Container>
  )
}

Component.propTypes = {
  ...SemanticDropdown.propTypes,
  intl: intlShape.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export const StylesLayoutAlignDropdown = injectIntl(Component)
