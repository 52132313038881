import EditLogoBurger from '@configurator/components/editLogoBurger/EditLogoBurger'
import websiteLogoBurgerDelete from '@graphql/gql/website/websiteLogoBurgerDelete.gql'
import websiteLogoBurgerUpdate from '@graphql/gql/website/websiteLogoBurgerUpdate.gql'
import websiteMetaGet from '@graphql/gql/website/websiteMetaGet.gql'
import websiteMetaUpdate from '@graphql/gql/website/websiteMetaUpdate.gql'
import { withFormik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { MESSAGE_TYPE } from 'packages/enum'
import { open as openErrorModal } from 'packages/redux/modules/modalConfirmDelete/actions'
import { lockModal } from 'packages/redux/modules/modalProgress/actions'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  siteName: Yup.string().nullable(),
})

export default compose(
  connect(
    // mapStateToProps
    () => ({}),
    // mapDispatchToProps
    (dispatch) => ({
      lockModalProgress: () => dispatch(lockModal(true)),
      unLockModalProgress: () => dispatch(lockModal(false)),
      openErrorModal: ({ headerMessageId, yesMessageId, subMessageId, subMessageValues }) =>
        dispatch(
          openErrorModal({
            headerMessageId,
            yesMessageId,
            subMessageId,
            subMessageValues,
            hideCancelButton: true,
          })
        ),
    })
  ),
  graphql(websiteMetaUpdate, {
    props({ mutate }) {
      return {
        async updateMeta({ siteName, siteDescription, id, burgerLogo }) {
          try {
            return await mutate({
              variables: {
                siteName,
                siteDescription,
                websiteId: id,
                burgerLogo,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteLogoBurgerUpdate, {
    props({ mutate }) {
      return {
        async updateLogo({ file }) {
          try {
            return await mutate({
              variables: {
                file,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteLogoBurgerDelete, {
    props({ mutate }) {
      return {
        async deleteLogo() {
          try {
            return await mutate({
              variables: {},
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteMetaGet, {
    options() {
      return {
        variables: {},
        ssr: false,
        fetchPolicy: 'network-only',
      }
    },
    props({ data: { loading, websiteMetaGet, refetch } }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(websiteMetaGet)) {
        return
      }
      const { data } = websiteMetaGet

      return { initialValues: { ...data }, refetchMeta: refetch }
    },
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => {
      return {
        siteName: '',
        siteDescription: '',
        ...initialValues,
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit: async (
      {
        siteName,
        siteDescription,
        id,
        newLogo,
        logoSizeBurgerPercent,
        logoSizeBurgerPercentMobile,
      },
      {
        setSubmitting,
        setStatus,
        setFieldValue,
        props: { updateMeta, updateLogo, lockModalProgress, unLockModalProgress, refetchMeta },
      }
    ) => {
      let defaultError = 'siteName.update.error.header'

      if (newLogo) {
        lockModalProgress()
        try {
          const res = await updateLogo({
            file: newLogo,
          })
          unLockModalProgress()
          let {
            data: { websiteLogoBurgerUpdate: { errors, success } = {} },
          } = res
          if (!success) {
            unLockModalProgress()
            setSubmitting(false)
            setStatus({ error: errors._error || defaultError })
          } else {
            setFieldValue('newLogo', undefined)
          }
        } catch (err) {
          unLockModalProgress()
          setSubmitting(false)
          setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
        }
      }

      try {
        const res = await updateMeta({
          id,
          siteName,
          siteDescription,
          burgerLogo: {
            logoSizeBurgerPercent,
            logoSizeBurgerPercentMobile,
          },
        })
        setSubmitting(false)
        let {
          data: { websiteMetaUpdate: { errors, success } = {} },
        } = res
        if (success) {
          refetchMeta()
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.UPDATE_META,
            }),
            '*'
          )
        } else {
          setStatus({ error: errors._error || defaultError })
        }
      } catch (err) {
        setSubmitting(false)
        setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
      }
    },
  })
)(EditLogoBurger)
