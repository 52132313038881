import { STYLE_FEATURE_PROPERTIES } from '../enum'

export const DOMAIN_TYPES = {
  CUSTOM_DOMAIN: 'CUSTOM_DOMAIN',
  PREMIUM_DOMAIN: 'PREMIUM_DOMAIN',
}

// day plan for test only
export const PLAN_PRICE_DESCRIPTION = {
  day: 'per day billed daily',
  month: 'billing.plan.month',
  year: 'billing.plan.year',
}

// day plan for test only
export const PLAN_PRICE_INTERVALS = {
  day: 'day',
  month: 'month',
  year: 'year',
}

export const VAT_LAYER_KEY = '20767af96c30b1944cb1c02812845a7a'

export const STYLE_KEYS_FOR_THEME = [
  STYLE_FEATURE_PROPERTIES.HEADER_WIDTH_STYLE,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_STYLE,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_ID,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_COLOR,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_SIZE,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_PADDING_TOP,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_PADDING_BOTTOM,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_ID,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_COLOR,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_SIZE,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_TOP,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_BOTTOM,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_LOGO_MARGIN_TOP,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_LOGO_MARGIN_BOTTOM,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_CUSTOM_LOGO_TOP_PADDING,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_CUSTOM_LOGO_BOTTOM_PADDING,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_MARGIN_TOP,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_MARGIN_BOTTOM,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_ID,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_SIZE,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_COLOR,
  STYLE_FEATURE_PROPERTIES.HEADER_CUSTOM_LOGO_TOP_PADDING,
  STYLE_FEATURE_PROPERTIES.HEADER_CUSTOM_LOGO_BOTTOM_PADDING,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_TYPE,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_STYLE,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_LAYOUT_ALIGN,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_PADDING_TOP,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_PADDING_BOTTOM,
  STYLE_FEATURE_PROPERTIES.HEADER_BACKGROUND_COLOR,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_ID,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_NAME,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_SIZE,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_COLOR,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.HEADER_BACKGROUND_OPACITY,
  STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_POSITION,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_SIZE,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_ICON_COLOR,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_COLOR,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LOGO_SIZE,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LOGO_MARGIN_TOP,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LOGO_MARGIN_BOTTOM,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_SUBLOGO_SIZE,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_SUBLOGO_MARGIN_TOP,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_SUBLOGO_MARGIN_BOTTOM,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LAYOUT_ALIGN,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_PADDING_TOP,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_PADDING_BOTTOM,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_STYLE,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_NAME,
  STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_ID,
  STYLE_FEATURE_PROPERTIES.BURGER_BACKGROUND_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_CLOSE_ICON_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_STYLE,
  STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LAYOUT_ALIGN,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LAYOUT_ALIGN,
  STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_FONT_ID,
  STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LINKS_FONT_SIZE,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_SIZE,
  STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LINKS_TOP_PADDING,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_TOP_PADDING,
  STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LINKS_BOTTOM_PADDING,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_BOTTOM_PADDING,
  STYLE_FEATURE_PROPERTIES.BURGER_LOGO_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_SUBLOGO_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_FOOTER_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_FOOTER_SOCIAL_ICON_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_BACKGROUND_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_CLOSE_ICON_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_ID,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_STYLE,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_LOGO_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_SUBLOGO_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_FOOTER_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_FOOTER_SOCIAL_ICON_COLOR,

  STYLE_FEATURE_PROPERTIES.FOOTER_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.FOOTER_FONT_ID,
  STYLE_FEATURE_PROPERTIES.FOOTER_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.FOOTER_BACKGROUND_COLOR,
  STYLE_FEATURE_PROPERTIES.FOOTER_BACKGROUND_OPACITY,
  STYLE_FEATURE_PROPERTIES.FOOTER_COLOR,
  STYLE_FEATURE_PROPERTIES.FOOTER_SIZE,
  STYLE_FEATURE_PROPERTIES.FOOTER_MOBILE_SIZE,
  STYLE_FEATURE_PROPERTIES.FOOTER_MOBILE_LAYOUT_ALIGN,
  STYLE_FEATURE_PROPERTIES.FOOTER_OVER_SLIDESHOW,
  STYLE_FEATURE_PROPERTIES.FOOTER_HIDDEN,
  STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_ID,
  STYLE_FEATURE_PROPERTIES.CONTACTS_COLOR,
  STYLE_FEATURE_PROPERTIES.CONTACTS_SIZE,
  STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_ID,
  STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_COLOR,
  STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_ID,
  STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_COLOR,
  STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_ID,
  STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_COLOR,
  STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_WEIGHT,

  STYLE_FEATURE_PROPERTIES.ALBUM_MOBILE_TITLE_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_MOBILE_DESCRIPTION_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_MOBILE_PHOTO_NOTE_SIZE,

  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_FONT_ID,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_COLOR,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_FONT_WEIGHT,

  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_PAGE_TITLE_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_PAGE_TITLE_FONT_ID,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_PAGE_TITLE_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_PAGE_TITLE_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_PAGE_TITLE_COLOR,

  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_FONT_ID,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_COLOR,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_FONT_WEIGHT,

  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_MOBILE_TITLE_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_MOBILE_DESCRIPTION_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_LIST_MOBILE_PAGE_TITLE_SIZE,

  STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_FONT_ID,
  STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_COLOR,
  STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_SIZE,
  STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_COLOR,
  STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_SIZE,
  STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_FONT_ID,
  STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_COLOR,
  STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_SIZE,
  STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_SOURCE,
  STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_ID,

  STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_TYPE,
  STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_BACKGROUND_COLOR,
  STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_SIZE,
  STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_COLOR,
  STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_NAME,
  STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_SOURCE,
  STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_FONT_ID,
  STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_FONT_WEIGHT,

  STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_MOBILE_TEXT_SIZE,

  STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_ID,
  STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_COLOR,
  STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_SIZE,
  STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_COLOR,
  STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_WEIGHT,
  STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_ID,
  STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_SIZE,
  STYLE_FEATURE_PROPERTIES.BACKGROUND_COLOR,
  STYLE_FEATURE_PROPERTIES.HOVER_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_COLOR,
  STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_COLOR,
  STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_VARIANT,
  STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM,
]
