import PropTypes from 'prop-types'
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Semantic } from 'packages/components'
import { Modal } from 'packages/components/modal'
import { FormDropdown, FormTextArea } from 'packages/components/inputs'
import { formProptypes } from 'packages/utils/formikPropTypes'
import Message from 'packages/components/message/Message'
import { MAX_IMAGE_SIZE } from '@configurator/constants/upload'
import ModalConfirmDelete from 'packages/components/modalConfirmDelete'
import AlignCheckbox from 'packages/components/alignCheckbox/alignCheckbox'
import { getDomain } from 'packages/helpers/Helper'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { MESSAGE_TYPE } from 'packages/enum'
import {
  WEBSITE_BLOCK,
  BLOCK_PROPERTY_KEYS,
  WEBSITE_PAGE_TYPES,
  CONTACT_FORM_LOCALE,
  CONTACT_FORM_ALIGN,
  WEBSITE_PAGE_BLOCK_TYPES,
} from 'packages/enum'
import { Header, Content } from '@configurator/components/modals/nodes'
import {
  StyledDropdown,
  StyledTextareaContainer,
  UploadImageWrapper,
  AlignContainer,
} from './nodes'

import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useSubscription } from '@configurator/providers/subscription'
import { useCallback } from 'react'
import MainSettingsTab from './mainSettingsTab'
import SeoSettingsTab from './seoSettingsTab'
import DisplayModeTab from './displayModeTab'
import { useUploadImage } from '../common/hooks/useUploadImage'
import ModalFooterButtons from '../common/modalFooterButtons/modalFooterButtons'
import ModalTabs from '../common/modalTabs/modalTabs'
import UploadImageBlock from '../common/uploadImageBlock/uploadImageBlock'
import { ContactDisplayModeForm } from './contactDisplayModeForm'
import { PhotosDisplayModeForm } from './photosDisplayModeForm'
import { AlbumListDisplayModeForm } from './albumListDisplayModeForm'
import { AlbumDetailsDisplayModeForm } from './albumDetailsDisplayModeForm'

const languageDropdownOptions = [
  {
    key: CONTACT_FORM_LOCALE.de,
    value: CONTACT_FORM_LOCALE.de,
    text: 'Deutsch',
  },
  {
    key: CONTACT_FORM_LOCALE.en,
    value: CONTACT_FORM_LOCALE.en,
    text: 'English',
  },
  {
    key: CONTACT_FORM_LOCALE.fr,
    value: CONTACT_FORM_LOCALE.fr,
    text: 'French',
  },
  {
    key: CONTACT_FORM_LOCALE.ja,
    value: CONTACT_FORM_LOCALE.ja,
    text: 'Japanese',
  },
  {
    key: CONTACT_FORM_LOCALE.ru,
    value: CONTACT_FORM_LOCALE.ru,
    text: 'Russian',
  },
  {
    key: CONTACT_FORM_LOCALE.sp,
    value: CONTACT_FORM_LOCALE.sp,
    text: 'Spanish',
  },
]

const modalStyle = {
  width: '580px',
  height: 'initial',
}

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const PageUpdate = (
  {
    open,
    onClose,
    handleSubmit,
    errors,
    status,
    isSubmitting,
    loading,
    values,
    setFieldValue,
    deleteBlockProperty,
    updateBlockProperty,
    lockModalProgress,
    unLockModalProgress,
    refetchData,
    refetchPagePreview,
    meta,
    initialValues,
    openErrorModal,
    closeErrorModal,
    slideshowEnabled,
    premiumDomain,
    domain,
    title,
    // description,
    seoPhotoUrl,
  },
  { intl: { formatMessage } }
) => {
  const [currentSeoPhotoUrl, setCurrentSeoPhotoUrl] = useState(seoPhotoUrl)
  useEffect(() => {
    setCurrentSeoPhotoUrl(seoPhotoUrl)
  }, [seoPhotoUrl])
  const prevValues = usePrevious(values)
  const [showModalDeleteImage, setShowModalDeleteImage] = useState(false)
  const { permissions } = useSubscription()
  const [activeTab, setActiveTab] = useState(0)

  const { handleDeletePreviewFile, handleDropFile, isFileReady, preview } = useUploadImage(
    setFieldValue,
    'file'
  )

  const isV2Template = meta.templateName?.includes('v2')

  const {
    handleDeletePreviewFile: handleDeleteSeoPreviewFile,
    handleDropFile: handleDropSeoFile,
    isFileReady: isSeoFileReady,
    preview: seoPreview,
  } = useUploadImage(setFieldValue, 'seoImage')

  const deleteSeoPhotoUrl = () => {
    if (get(seoPreview, 'preview', '')) {
      handleDeleteSeoPreviewFile()
    } else {
      setFieldValue('deleteSeoPhotoUrl', true)
      setCurrentSeoPhotoUrl('')
    }
  }

  const undoDeleteSeoPhotoUrlOnDropFile = (files) => {
    handleDropSeoFile(files)
    setFieldValue('deleteSeoPhotoUrl', false)
  }

  const handleTabClick = useCallback(
    (idx) => {
      if (idx !== activeTab) {
        setActiveTab(idx)
      }
    },
    [activeTab]
  )

  const isHTMLPage = useMemo(() => {
    if (values?.blocks && values.blocks[0]?.type === WEBSITE_PAGE_TYPES.HTML) {
      return true
    }
    return false
  }, [values.blocks])

  const isLinkPage = useMemo(() => {
    if (values?.blocks && values.blocks[0]?.type === WEBSITE_PAGE_TYPES.EXTERNAL_LINK) {
      return true
    }
    return false
  }, [values.blocks])

  const isPhotoPage = (values.blocks || []).find(
    (el) => el.type === WEBSITE_BLOCK.EXPANDED_ALBUM_LIST
  )

  const isAlbumList = (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST)

  const error = Object.keys(errors).length || (status && !!status.error)

  const contactBlock = useMemo(() => {
    return (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.CONTACTS)
  }, [values.blocks])

  useEffect(() => {
    if (prevValues?.id !== values.id) {
      if (contactBlock) {
        const userPhotoText = (contactBlock.blockProperties || []).find(
          (el) => el.key === BLOCK_PROPERTY_KEYS.userPhotoText
        )

        if (userPhotoText && !values.userPhotoText) {
          setFieldValue('userPhotoText', userPhotoText.value)
        }

        const align = (contactBlock.blockProperties || []).find(
          (el) => el.key === BLOCK_PROPERTY_KEYS.contactFormAlign
        )

        if (align && align.value) {
          setFieldValue('align', align.value)
        }

        const language = (contactBlock.blockProperties || []).find(
          (el) => el.key === BLOCK_PROPERTY_KEYS.contactFormLang
        )

        if (language && language.value) {
          setFieldValue('language', language.value)
        }
      }

      setFieldValue('stacked', !values.expanded)
      setFieldValue('album', !values.zoom)
      setFieldValue('hideHeader', !values.showHeader)
    }

    if (prevValues?.mainNavigation !== values.mainNavigation) {
      setFieldValue('footerNavigation', !values.mainNavigation)
    }

    if (prevValues?.footerNavigation !== values.footerNavigation) {
      setFieldValue('mainNavigation', !values.footerNavigation)
    }

    if (prevValues?.stacked !== values.stacked) {
      setFieldValue('expanded', !values.stacked)

      if (values.stacked) {
        setFieldValue('zoom', false)
      }
    }
    if (prevValues?.expanded !== values.expanded) {
      setFieldValue('stacked', !values.expanded)

      if (!values.expanded) {
        setFieldValue('zoom', false)
      }
    }

    if (prevValues?.album !== values.album) {
      setFieldValue('zoom', !values.album)
    }

    if (prevValues?.zoom !== values.zoom) {
      setFieldValue('album', !values.zoom)
    }
  }, [contactBlock, prevValues, setFieldValue, values])

  const showExpandedOptions = useMemo(() => {
    return (
      (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST) &&
      get(meta, 'canExpand')
    )
  }, [values.blocks, meta])

  const onDropRejected = (files, accept) => {
    if (files.find((el) => el.size > MAX_IMAGE_SIZE)) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileSize.description',
        subMessageValues: { size: MAX_IMAGE_SIZE / 1024 / 1024 },
        hideCancelButton: true,
      })
    }
    const acceptArr = accept.split(', ')
    if (files.find((el) => !acceptArr.includes(el.type))) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileType.description',
        subMessageValues: { types: accept.replace(/image\//g, '') },
        hideCancelButton: true,
      })
    }
  }

  const renderContactOptionsBlock = () => {
    if (contactBlock) {
      const photoBlockProperty = (contactBlock.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.userPhotoMediaUrl
      )
      return (
        <>
          <PermissionsOverlay isAllowed={permissions.MULTILANG_CONTACT}>
            <StyledDropdown>
              <FormDropdown
                name='language'
                label='newDesign.page.update.field.language'
                options={languageDropdownOptions}
              />
            </StyledDropdown>
          </PermissionsOverlay>
          <StyledTextareaContainer>
            <AlignContainer>
              {formatMessage({
                id: 'page.update.field.textAlign',
              })}
              <AlignCheckbox
                name='left'
                alignment='left'
                active={values.align === CONTACT_FORM_ALIGN.LEFT}
              />
              <AlignCheckbox
                name='center'
                alignment='center'
                active={values.align === CONTACT_FORM_ALIGN.CENTER}
              />
              <AlignCheckbox
                name='right'
                alignment='right'
                active={values.align === CONTACT_FORM_ALIGN.RIGHT}
              />
            </AlignContainer>
            <FormTextArea label='newDesign.page.update.field.additionalText' name='userPhotoText' />
          </StyledTextareaContainer>

          {/* <StyledInput>
                        <FormInput
                            name="altEmail"
                            type="email"
                            label="newDesign.page.update.contacts.field.altEmail.label"
                        />
                        <Description size={12}>
                            {formatMessage({
                                id: "newDesign.page.update.contacts.field.altEmail.desc",
                            })}
                        </Description>
                    </StyledInput> */}

          <UploadImageWrapper>
            <UploadImageBlock
              uploadModalTitle={
                !!contactBlock ? 'newDesign.page.update.contacts.upload.modal.title' : ''
              }
              title={'newDesign.page.update.field.selectPhoto'}
              description={'newDesign.page.update.contacts.uploadImg.desc'}
              isSubmitting={isSubmitting}
              photoBlockProperty={photoBlockProperty}
              onDropFile={handleDropFile}
              onDropReject={onDropRejected}
              isFileReady={Boolean(isFileReady || photoBlockProperty)}
              fileName={get(preview, 'file.name')}
              onDeleteImageClick={() =>
                isFileReady ? handleDeletePreviewFile() : setShowModalDeleteImage(true)
              }
              fileSrc={
                photoBlockProperty
                  ? getDomain(photoBlockProperty.value)
                  : isFileReady
                  ? get(preview, 'preview', '')
                  : ''
              }
            />
          </UploadImageWrapper>
        </>
      )
    }
    return null
  }

  const getHeaderTitle = useMemo(() => {
    if (get(values, 'blocks[0].blockType') === WEBSITE_PAGE_BLOCK_TYPES.ECWID) {
      return 'newDesign.page.update.header.ecwid'
    }
    switch (get(values, 'blocks[0].type')) {
      case WEBSITE_PAGE_TYPES.EXTERNAL_LINK:
        return 'newDesign.page.update.header.url'
      case WEBSITE_PAGE_TYPES.CONTACTS:
        return 'newDesign.page.update.header.contact'
      case WEBSITE_PAGE_TYPES.TEXT:
        return 'newDesign.page.update.header.text'
      case WEBSITE_PAGE_TYPES.HTML:
        return 'newDesign.page.update.header.html'
      case 'EXPANDED_ALBUM_LIST':
        return 'newDesign.page.update.header.simple'
      default:
        return 'newDesign.page.update.header.photo'
    }
  }, [values])

  const handleClose = () => {
    if (!isEqual(values, initialValues)) {
      openErrorModal({
        subMessageId: 'discardChanges.subMessage',
        yesMessageId: 'discardChanges.yesMessage',
        hideHeader: true,
        onClickYes: () => {
          onClose()
          closeErrorModal()
        },
        styles: { width: '450px' },
      })
    } else {
      onClose()
    }
  }

  const tabs = useMemo(() => {
    if (showExpandedOptions) {
      return [
        {
          text: 'newDesign.page.update.tabs.main',
          intercomAttribute: 'PageTabSettings',
        },
        {
          text: 'newDesign.page.update.tabs.seo',
          intercomAttribute: 'PageTabSEO',
        },
        {
          text: 'newDesign.page.update.tabs.displayMode',
          intercomAttribute: 'PageTabDisplayMode',
        },
      ]
    } else if (!!contactBlock) {
      const add = isV2Template
        ? [
            {
              text: 'Display Mode',
              intercomAttribute: 'PageCustomizationTabSEO',
            },
          ]
        : []
      return [
        {
          text: 'newDesign.page.update.tabs.main',
          intercomAttribute: 'PageTabSettings',
        },
        {
          text: 'newDesign.page.update.tabs.seo',
          intercomAttribute: 'PageTabSEO',
        },
        ...add,
      ]
    } else {
      return [
        {
          text: 'newDesign.page.update.tabs.main',
          intercomAttribute: 'PageTabSettings',
        },
        {
          text: 'newDesign.page.update.tabs.seo',
          intercomAttribute: 'PageTabSEO',
        },
      ]
        .concat(
          isPhotoPage
            ? [
                {
                  text: 'Display Mode',
                  intercomAttribute: 'PageCustomizationTabSEO',
                },
              ]
            : []
        )
        .concat(
          isAlbumList && isV2Template
            ? [
                {
                  text: 'Display Mode',
                  intercomAttribute: 'PageCustomizationTabSEO',
                },
              ]
            : []
        )
        .concat(
          isAlbumList && isV2Template
            ? [
                {
                  text: 'Display Album Mode',
                  intercomAttribute: 'PageCustomizationTabSEO',
                },
              ]
            : []
        )
    }
  }, [showExpandedOptions, contactBlock, isV2Template, isAlbumList, isPhotoPage])

  const userDomain = `https://${premiumDomain || `${domain}.vsble.me`}`

  // contact
  const getContactPageBlockProperties = () => {
    const align = (contactBlock.blockProperties || []).find(
      (el) => el.key === BLOCK_PROPERTY_KEYS.align
    )
    return {
      align: align?.value,
    }
  }

  // photos
  const getPhotosPageBlockProperties = () => {
    const block = (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.EXPANDED_ALBUM_LIST)
    if (block) {
      const rowsDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.rowsDesktop
      )
      const modeDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.modeDesktop
      )
      const titleDescriptionPositionDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.titleDescriptionPositionDesktop
      )
      const rowsMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.rowsMobile
      )
      const modeMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.modeMobile
      )
      const titleDescriptionPositionMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.titleDescriptionPositionMobile
      )
      const imageCaptionsMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.imageCaptionsMobile
      )
      const imageCaptionsDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.imageCaptionsDesktop
      )

      return {
        rowsDesktop: rowsDesktop?.value,
        modeDesktop: modeDesktop?.value,
        titleDescriptionPositionDesktop: titleDescriptionPositionDesktop?.value,
        rowsMobile: rowsMobile?.value,
        modeMobile: modeMobile?.value,
        titleDescriptionPositionMobile: titleDescriptionPositionMobile?.value,
        imageCaptionsMobile: imageCaptionsMobile?.value,
        imageCaptionsDesktop: imageCaptionsDesktop?.value,
      }
    }
  }
  // album list
  const getAlbumListPageBlockProperties = () => {
    const block = (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST)
    if (block) {
      // rowsDesktop: 'rowsDesktop',
      // modeDesktop: 'modeDesktop',
      // titleDescriptionPositionDesktop: 'titleDescriptionPositionDesktop',
      // rowsMobile: 'rowsMobile',
      // modeMobile: 'modeMobile',
      // titleDescriptionPositionMobile: 'titleDescriptionPositionMobile',
      // imageCaptions: 'imageCaptions',

      const rowsDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.rowsDesktop
      )
      const modeDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.modeDesktop
      )
      const titleDescriptionPositionDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.titleDescriptionPositionDesktop
      )
      const rowsMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.rowsMobile
      )
      const modeMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.modeMobile
      )
      const titleDescriptionPositionMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.titleDescriptionPositionMobile
      )

      return {
        rowsDesktop: rowsDesktop?.value,
        modeDesktop: modeDesktop?.value,
        titleDescriptionPositionDesktop: titleDescriptionPositionDesktop?.value,
        rowsMobile: rowsMobile?.value,
        modeMobile: modeMobile?.value,
        titleDescriptionPositionMobile: titleDescriptionPositionMobile?.value,
      }
    }
  } // album list
  const getAlbumDetailsPageBlockProperties = () => {
    const block = (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST)
    if (block) {
      // albumRowsDesktop: 'albumRowsDesktop',
      // albumModeDesktop: 'albumModeDesktop',
      // albumTitleDescriptionPositionDesktop: 'albumTitleDescriptionPositionDesktop',
      // albumRowsMobile: 'albumRowsMobile',
      // albumModeMobile: 'albumModeMobile',
      // albumTitleDescriptionPositionMobile: 'albumTitleDescriptionPositionMobile',
      // albumImageCaptions: 'albumImageCaptions',

      const albumRowsDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumRowsDesktop
      )
      const albumModeDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumModeDesktop
      )
      const albumTitleDescriptionPositionDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumTitleDescriptionPositionDesktop
      )
      const albumRowsMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumRowsMobile
      )
      const albumModeMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumModeMobile
      )
      const albumTitleDescriptionPositionMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumTitleDescriptionPositionMobile
      )
      const albumSideMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumSideMobile
      )
      const albumSideDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumSideDesktop
      )
      const albumImageCaptionsMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumImageCaptionsMobile
      )
      const albumImageCaptionsDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumImageCaptionsDesktop
      )

      return {
        albumRowsDesktop: albumRowsDesktop?.value,
        albumModeDesktop: albumModeDesktop?.value,
        albumTitleDescriptionPositionDesktop: albumTitleDescriptionPositionDesktop?.value,
        albumRowsMobile: albumRowsMobile?.value,
        albumModeMobile: albumModeMobile?.value,
        albumTitleDescriptionPositionMobile: albumTitleDescriptionPositionMobile?.value,
        albumImageCaptionsMobile: albumImageCaptionsMobile?.value,
        albumImageCaptionsDesktop: albumImageCaptionsDesktop?.value,
        albumSideMobile: albumSideMobile?.value,
        albumSideDesktop: albumSideDesktop?.value,
      }
    }
  }

  // contacts
  const saveContactPageBlockProperties = async (props) => {
    lockModalProgress()
    const res = await updateBlockProperty({
      blockId: contactBlock.id,
      propertyKey: BLOCK_PROPERTY_KEYS.align,
      propertyValue: props.align,
    })
    let {
      data: { blockPropertyUpdate: { success } = {} },
    } = res
    if (success) {
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*'
      )
    }

    unLockModalProgress()
    console.log(res)
    onClose()
  }
  // photos
  const savePhotosPageBlockProperties = async (props) => {
    const block = (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.EXPANDED_ALBUM_LIST)
    if (block) {
      // rowsDesktop: 'rowsDesktop',
      // modeDesktop: 'modeDesktop',
      // titleDescriptionPositionDesktop: 'titleDescriptionPositionDesktop',
      // rowsMobile: 'rowsMobile',
      // modeMobile: 'modeMobile',
      // titleDescriptionPositionMobile: 'titleDescriptionPositionMobile',
      // imageCaptions: 'imageCaptions',
      lockModalProgress()
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.rowsDesktop,
        propertyValue: props.rowsDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.modeDesktop,
        propertyValue: props.modeDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.titleDescriptionPositionDesktop,
        propertyValue: props.titleDescriptionPositionDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.rowsMobile,
        propertyValue: props.rowsMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.modeMobile,
        propertyValue: props.modeMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.titleDescriptionPositionMobile,
        propertyValue: props.titleDescriptionPositionMobile,
      })

      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.imageCaptionsMobile,
        propertyValue: props.imageCaptionsMobile,
      })

      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.imageCaptionsDesktop,
        propertyValue: props.imageCaptionsDesktop,
      })

      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*'
      )
      unLockModalProgress()
      onClose()
    }
  }
  // album list
  const saveAlbumListPageBlockProperties = async (props) => {
    const block = (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST)
    if (block) {
      // rowsDesktop: 'rowsDesktop',
      // modeDesktop: 'modeDesktop',
      // titleDescriptionPositionDesktop: 'titleDescriptionPositionDesktop',
      // rowsMobile: 'rowsMobile',
      // modeMobile: 'modeMobile',
      // titleDescriptionPositionMobile: 'titleDescriptionPositionMobile',

      lockModalProgress()
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.rowsDesktop,
        propertyValue: props.rowsDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.modeDesktop,
        propertyValue: props.modeDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.titleDescriptionPositionDesktop,
        propertyValue: props.titleDescriptionPositionDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.rowsMobile,
        propertyValue: props.rowsMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.modeMobile,
        propertyValue: props.modeMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.titleDescriptionPositionMobile,
        propertyValue: props.titleDescriptionPositionMobile,
      })

      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*'
      )
      unLockModalProgress()
      onClose()
    }
  }

  // album details
  const saveAlbumDetailsPageBlockProperties = async (props) => {
    const block = (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST)
    if (block) {
      // albumRowsDesktop: 'albumRowsDesktop',
      // albumModeDesktop: 'albumModeDesktop',
      // albumTitleDescriptionPositionDesktop: 'albumTitleDescriptionPositionDesktop',
      // albumRowsMobile: 'albumRowsMobile',
      // albumModeMobile: 'albumModeMobile',
      // albumTitleDescriptionPositionMobile: 'albumTitleDescriptionPositionMobile',
      // albumImageCaptions: 'albumImageCaptions',
      // albumSideMobile: 'albumSideMobile',
      // albumSideDesktop: 'albumSideDesktop',

      lockModalProgress()
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumRowsDesktop,
        propertyValue: props.albumRowsDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumModeDesktop,
        propertyValue: props.albumModeDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumTitleDescriptionPositionDesktop,
        propertyValue: props.albumTitleDescriptionPositionDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumRowsMobile,
        propertyValue: props.albumRowsMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumModeMobile,
        propertyValue: props.albumModeMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumTitleDescriptionPositionMobile,
        propertyValue: props.albumTitleDescriptionPositionMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumImageCaptionsMobile,
        propertyValue: props.albumImageCaptionsMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumImageCaptionsDesktop,
        propertyValue: props.albumImageCaptionsDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumSideDesktop,
        propertyValue: props.albumSideDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumSideMobile,
        propertyValue: props.albumSideMobile,
      })

      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*'
      )
      unLockModalProgress()
      onClose()
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      styles={modalStyle}
      mobileWide
      variant='gray'
      showHeaderCloseIcon
      showCloseIcon={false}
    >
      <Content>
        <Semantic.Form
          noValidate
          error={error}
          loading={isSubmitting || loading}
          onSubmit={handleSubmit}
        >
          <Header>
            <span>{formatMessage({ id: getHeaderTitle })}</span>
          </Header>

          <ModalTabs tabs={tabs} activeIdx={activeTab} onTabClick={handleTabClick} />

          <Message error={error} messages={errors} content={status && status.error} />

          {activeTab === 0 ? (
            <MainSettingsTab
              permissions={permissions}
              isLinkPage={isLinkPage}
              slideshowEnabled={slideshowEnabled}
              isHTMLPage={isHTMLPage}
              isContactPage={!!contactBlock}
              values={values}
              isSubmitting={isSubmitting}
              isAlbumList={isAlbumList}
              isV2Template={isV2Template}
              isPhotoPage={isPhotoPage}
              renderContactOptionsBlock={renderContactOptionsBlock}
              url={`${userDomain}/`}
            />
          ) : activeTab === 1 ? (
            <SeoSettingsTab
              isLinkPage={isLinkPage}
              isHTMLPage={isHTMLPage}
              values={values}
              isSubmitting={isSubmitting}
              onDropSeoFile={undoDeleteSeoPhotoUrlOnDropFile}
              onRejectSeoFile={onDropRejected}
              isFileReady={isSeoFileReady}
              fileSrc={get(seoPreview, 'preview', '')}
              fileName={get(seoPreview, 'file.name', '')}
              onDeleteImagePreview={deleteSeoPhotoUrl}
              pageTitle={title}
              domain={userDomain}
              seoPhotoUrl={currentSeoPhotoUrl}
              seoDescription={meta.seoDescription}
            />
          ) : activeTab === 2 && showExpandedOptions ? (
            <DisplayModeTab setFieldValue={setFieldValue} values={values} />
          ) : null}
          {(activeTab === 2 && !showExpandedOptions) || activeTab === 3 ? null : (
            <ModalFooterButtons disabled={isSubmitting} onClose={onClose} />
          )}
        </Semantic.Form>

        {activeTab === 2 && !showExpandedOptions ? (
          <div style={{ width: '100%' }}>
            {contactBlock ? (
              <ContactDisplayModeForm
                initialValues={getContactPageBlockProperties()}
                onSubmit={saveContactPageBlockProperties}
                onClose={onClose}
              />
            ) : null}
            {isPhotoPage ? (
              <PhotosDisplayModeForm
                initialValues={getPhotosPageBlockProperties()}
                onSubmit={savePhotosPageBlockProperties}
                onClose={onClose}
              />
            ) : null}
            {isAlbumList ? (
              <AlbumListDisplayModeForm
                initialValues={getAlbumListPageBlockProperties()}
                onSubmit={saveAlbumListPageBlockProperties}
                onClose={onClose}
              />
            ) : null}
          </div>
        ) : null}

        {activeTab === 3 ? (
          <div style={{ width: '100%' }}>
            {isAlbumList ? (
              <AlbumDetailsDisplayModeForm
                initialValues={getAlbumDetailsPageBlockProperties()}
                onSubmit={saveAlbumDetailsPageBlockProperties}
                onClose={onClose}
              />
            ) : null}
          </div>
        ) : null}
      </Content>
      <ModalConfirmDelete
        open={showModalDeleteImage}
        onClickYes={() => {
          const userPhotoProperty =
            (contactBlock.blockProperties || []).find(
              (el) => el.key === BLOCK_PROPERTY_KEYS.userPhoto
            ) || {}
          deleteBlockProperty({ propertyId: userPhotoProperty.id })
            .then(refetchData)
            .then(refetchPagePreview)
          setShowModalDeleteImage(false)
        }}
        onClickCancel={() => setShowModalDeleteImage(false)}
        onClose={() => setShowModalDeleteImage(false)}
      />
    </Modal>
  )
}

PageUpdate.propTypes = {
  url: PropTypes.string,
  pageId: PropTypes.number.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteBlockProperty: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  refetchPagePreview: PropTypes.func,
  openErrorModal: PropTypes.func,
  closeErrorModal: PropTypes.func,
  meta: PropTypes.shape({
    canExpand: PropTypes.bool,
  }),
  slideshowEnabled: PropTypes.bool,
  seoPhotoUrl: PropTypes.string,
  ...formProptypes,
}

PageUpdate.defaultProps = {
  open: false,
  refetchPagePreview: () => null,
  openErrorModal: () => null,
  closeErrorModal: () => null,
  meta: {
    canExpand: undefined,
  },
  slideshowEnabled: false,
}

PageUpdate.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default PageUpdate
