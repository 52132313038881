import { MAX_IMAGE_SIZE } from '@configurator/constants/upload'
import get from 'lodash/get'
import { Semantic } from 'packages/components'
import { Button, TrashButton } from 'packages/components/buttons'
import Image from 'packages/components/image/Image'
import { FormSlider } from 'packages/components/inputs'
import Message from 'packages/components/message/Message'
import ModalConfirmDelete from 'packages/components/modalConfirmDelete'
import { MESSAGE_TYPE } from 'packages/enum'
import { getDomain } from 'packages/helpers/Helper'
import { formProptypes } from 'packages/utils/formikPropTypes'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'

import { useSubscription } from '@configurator/providers/subscription'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import {
  ButtonRow,
  FormRow,
  ImageComponent,
  Logo,
  LogoDescription,
  UploadLogo,
  Wrapper,
} from './nodes'

const EditLogoBurger = (
  {
    handleSubmit,
    errors,
    status,
    isSubmitting,
    loading,
    setFieldValue,
    values,
    deleteLogo,
    refetchMeta,
    openErrorModal,
    disableInputs,
    disableUploadLogo,
    textMode,
    isMobile,
  },
  { intl: { formatMessage } }
) => {
  const { permissions } = useSubscription()

  const error = Object.keys(errors).length || (status && !!status.error)

  const [isLogoFileReady, setIsLogoFileReady] = useState(false)
  const [logoPreview, setLogoPreview] = useState()
  const [showModalDeleteLogo, setShowModalDeleteLogo] = useState(false)
  const [previewLogoData, setPreviewLogoData] = useState()

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result || '')
      }
    })
  }

  useEffect(() => {
    if (!logoPreview) {
      setPreviewLogoData(undefined)
    } else {
      getBase64(logoPreview.file).then((file) => {
        setPreviewLogoData(file)
      })
    }
  }, [logoPreview])

  useEffect(() => {
    window.frames['preview-frame']?.postMessage(
      JSON.stringify({
        name: MESSAGE_TYPE.SET_LOGO_PREVIEW,
        logoPreview: previewLogoData,
        logoSizeBurgerPercent: values.logoSizeBurgerPercent,
        logoSizeBurgerPercentMobile: values.logoSizeBurgerPercentMobile,
      }),
      '*'
    )
  }, [values.logoSizeBurgerPercent, values.logoSizeBurgerPercentMobile, previewLogoData])

  const memoizedLogo = useMemo(() => get(values, 'burgerLogo'), [values])

  useEffect(() => {
    setFieldValue('logoSizeBurgerPercent', get(memoizedLogo, 'logoSizeBurgerPercent'))
    setFieldValue('logoSizeBurgerPercentMobile', get(memoizedLogo, 'logoSizeBurgerPercentMobile'))
  }, [memoizedLogo, setFieldValue])

  const handleDeleteLogo = useCallback(() => {
    deleteLogo()
      .then(refetchMeta)
      .then(() =>
        window.frames['preview-frame']?.postMessage(
          JSON.stringify({
            name: MESSAGE_TYPE.UPDATE_META,
          }),
          '*'
        )
      )
    setFieldValue('newLogo', undefined)
    setFieldValue('logoSizeBurgerPercent', undefined)
    setFieldValue('logoSizeBurgerPercentMobile', undefined)
    setLogoPreview(undefined)
    setIsLogoFileReady(false)
    setShowModalDeleteLogo(false)
  }, [deleteLogo, refetchMeta, setFieldValue])

  useEffect(() => {
    if (textMode && values?.burgerLogo) {
      handleDeleteLogo()
    }
  }, [handleDeleteLogo, textMode, values?.burgerLogo])

  if (isMobile && !values?.burgerLogo) return null

  const handleDropFile = (files) => {
    if (files && files.length) {
      const file = files[0]
      const preview = {
        index: 0,
        file,
        name: file.name,
        preview: window.URL.createObjectURL(file),
      }

      setIsLogoFileReady(true)
      setLogoPreview(preview)
      setFieldValue('newLogo', file)
      setFieldValue('logoSizeBurgerPercent', 1)
      setFieldValue('logoSizeBurgerPercentMobile', 1)
    }
  }

  const onDropRejected = (files, accept, maxSize) => {
    if (files.find((el) => el.size > maxSize)) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileSize.description',
        subMessageValues: { size: maxSize / 1024 / 1024 },
      })
    }
    const acceptArr = accept.split(', ')
    if (files.find((el) => !acceptArr.includes(el.type))) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileType.description',
        subMessageValues: { types: accept.replace(/image\//g, '') },
      })
    }
  }

  const renderUpload = () => {
    const isFileReady = isLogoFileReady
    const preview = logoPreview

    const accept = 'image/jpeg, image/gif, image/png'
    const maxSize = MAX_IMAGE_SIZE

    return (
      <UploadLogo>
        {isFileReady ? (
          <div className='image-preview'>
            <img src={preview.preview} alt='' />
            <span>{`/...${preview.file.name.slice(-10)}`}</span>
            <Dropzone
              accept={accept}
              onDrop={(files) => handleDropFile(files)}
              multiple={false}
              maxSize={maxSize}
              onDropRejected={(files) => onDropRejected(files, accept, maxSize)}
            >
              {({ getInputProps, getRootProps }) => (
                <div {...getRootProps()} className='input'>
                  <input {...getInputProps()} />
                  {formatMessage({
                    id: 'editLogo.button.change',
                  })}
                </div>
              )}
            </Dropzone>
            <TrashButton
              onClick={() => {
                setLogoPreview(undefined)
                setIsLogoFileReady(false)
                setFieldValue('newLogo', undefined)
                setFieldValue('logoSizeBurgerPercent', undefined)
                setFieldValue('logoSizeBurgerPercentMobile', undefined)
              }}
            />
          </div>
        ) : (
          <Dropzone
            accept={accept}
            onDrop={(files) => handleDropFile(files)}
            multiple={false}
            maxSize={maxSize}
            onDropRejected={(files) => onDropRejected(files, accept, maxSize)}
          >
            {({ getInputProps, getRootProps }) => (
              <div {...getRootProps()} className='input'>
                <input {...getInputProps()} />
                {formatMessage({
                  id: 'editLogo.upload.customLogo',
                })}
              </div>
            )}
          </Dropzone>
        )}
      </UploadLogo>
    )
  }

  const renderImage = () => {
    const { hashPath, height = 1, width = 1 } = get(values, 'burgerLogo', {})

    const accept = 'image/jpeg, image/gif, image/png'
    const maxSize = MAX_IMAGE_SIZE

    return (
      <Logo>
        <ImageComponent>
          <Image
            aspectRatio={width / height}
            src={getDomain(hashPath)}
            imgStyle={{ objectFit: 'contain' }}
            alt=''
          />
        </ImageComponent>

        <Dropzone
          accept={accept}
          onDrop={(files) => handleDropFile(files)}
          multiple={false}
          maxSize={maxSize}
          onDropRejected={(files) => onDropRejected(files, accept, maxSize)}
        >
          {({ getInputProps, getRootProps }) => (
            <div {...getRootProps()} className='input'>
              <input {...getInputProps()} />
              {formatMessage({ id: 'editLogo.button.change' })}
            </div>
          )}
        </Dropzone>
        <TrashButton onClick={() => setShowModalDeleteLogo(true)} />
      </Logo>
    )
  }

  return (
    <Wrapper isCustomization={disableInputs || disableUploadLogo}>
      <Semantic.Form
        noValidate
        error={error}
        loading={isSubmitting || loading}
        onSubmit={(e) => {
          if (disableInputs || disableUploadLogo) e.stopPropagation()
          handleSubmit()
        }}
      >
        <Message error={error} errors={errors} content={status && status.error} />

        {!disableUploadLogo && (
          <>
            {!isMobile && (
              <FormRow>
                <LogoDescription>
                  {disableInputs || formatMessage({ id: 'editLogo.label.customLogo' })}
                  <span>{formatMessage({ id: 'editLogo.text.customLogo' })}</span>
                </LogoDescription>
              </FormRow>
            )}

            <PermissionsOverlay isAllowed={permissions.UPLOADABLE_LOGO}>
              {!isMobile && (
                <FormRow>
                  {!values.burgerLogo || values.newLogo ? renderUpload(true) : renderImage(true)}
                </FormRow>
              )}

              {(values.burgerLogo || values.newLogo) && (
                <FormRow>
                  <FormSlider
                    name={isMobile ? 'logoSizeBurgerPercentMobile' : 'logoSizeBurgerPercent'}
                    label={formatMessage({
                      id: 'slideshow.label.logoSize',
                    })}
                    min={0.2}
                    max={1.8}
                    step={0.05}
                    bottomMessages={[
                      formatMessage({
                        id: 'styles.inputs.fontSize.value.small',
                      }),
                      formatMessage({
                        id: 'styles.inputs.fontSize.value.medium',
                      }),
                      formatMessage({
                        id: 'styles.inputs.fontSize.value.large',
                      }),
                    ]}
                  />
                </FormRow>
              )}
            </PermissionsOverlay>
          </>
        )}

        <ButtonRow>
          <Button
            type='submit'
            fluid
            disabled={isSubmitting}
            content={formatMessage({ id: 'editLogo.button.save' })}
          />
        </ButtonRow>
      </Semantic.Form>

      {!disableUploadLogo && (
        <ModalConfirmDelete
          open={showModalDeleteLogo}
          onClickYes={handleDeleteLogo}
          onClickCancel={() => setShowModalDeleteLogo(false)}
          onClose={() => setShowModalDeleteLogo(false)}
        />
      )}
    </Wrapper>
  )
}

EditLogoBurger.propTypes = {
  initialValues: PropTypes.object.isRequired,
  deleteLogo: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  refetchMeta: PropTypes.func,
  openErrorModal: PropTypes.func,
  ...formProptypes,
}

EditLogoBurger.defaultProps = {
  refetchMeta: () => null,
  openErrorModal: () => null,
}

EditLogoBurger.contextTypes = {
  intl: PropTypes.object.isRequired,
}
export default EditLogoBurger
